@if (previewValue | isImage) {
    <div
        class="tw-w-96 tw-h-96 tw-relative"
        [attr.data-testid]="'preview-img-' + previewValue">
        <img
            alt="preview-image"
            class="tw-object-contain"
            [ngSrc]="previewValue"
            fill />
    </div>
} @else {
    <div
        [attr.data-testid]="'preview-text-' + previewValue"
        class="tw-break-words">
        {{ previewValue === 'undefined' ? '' : previewValue }}
    </div>
}
