<header-navigation>
    <ui-svg-icon
        slot="start"
        [icon]="'log'"
        (click)="goToBFCListUrl()"></ui-svg-icon>
    <ns-notifications-component
        *ffFeatureFlag="FeatureFlags.NotificationServiceIntegration"
        slot="end" />
</header-navigation>
@let feed = editableFeed();
@if (feed) {
    <ui-body
        [full]="true"
        class="ui-body">
        <ui-tabs class="tw-px-12">
            <ui-tab name="Main feed settings">
                <div class="tw-flex tw-flex-col tw-bg-ui-grey-98 tw-gap-4">
                    <div class="tw-flex tw-flex-row tw-gap-6 tw-items-center">
                        <publish-meta-category [feedId]="feed.mainFeed.id"></publish-meta-category>
                        <div class="tw-w-[1px] tw-h-[100px] tw-bg-ui-grey-89"></div>
                        <card-summary>
                            <h1 class="tw-mb-0">{{ feed.mainFeed.blueprint.fields.length }}</h1>
                            <p class="tw-mb-0">{{ 'Source fields selected' }}</p>
                            <div
                                class="tw-truncate"
                                [uiTooltip]="feed.mainFeed.blueprint.fields | extractNames">
                                {{ feed.mainFeed.blueprint.fields | extractNames }}
                            </div>
                        </card-summary>
                        <card-summary>
                            <h1 class="tw-mb-3">{{ 'Feed source' }}</h1>
                            <div class="tw-flex tw-gap-2 tw-items-center">
                                <mat-form-field class="tw-flex feed-editor-source-url">
                                    <input
                                        class="tw-truncate"
                                        matInput
                                        type="text"
                                        [value]="feed.mainFeed.sourceUrl"
                                        [readonly]="true"
                                        [uiTooltip]="feed.mainFeed.sourceUrl" />
                                    <ui-svg-icon
                                        class="tw-mt-3 tw-relative tw-bottom-2 tw-ml-2 tw-cursor-pointer"
                                        [icon]="'copy'"
                                        [uiTooltip]="'Copied!'"
                                        [uiTooltipTrigger]="'click'"
                                        (click)="copyURL($event, feed.mainFeed.sourceUrl)" />
                                </mat-form-field>
                            </div>
                        </card-summary>
                    </div>
                    <div class="tw-flex tw-w-full tw-flex-col">
                        <preview-carousel
                            feed
                            [previewData]="feed.previewData"></preview-carousel>
                        @if (feed.operationsSummary.length > 0) {
                            <div class="tw-mt-13">
                                <h1 class="tw-font-normal">Operations</h1>
                                <operations-summary [operationsSummary]="feed.operationsSummary!"></operations-summary>
                            </div>
                        }
                        <div class="tw-mt-13">
                            <h1 class="tw-font-normal">Settings</h1>
                            <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                                <div class="tw-flex tw-flex-col tw-gap-2">
                                    <mat-form-field>
                                        <mat-label>Name</mat-label>
                                        <input
                                            matInput
                                            placeholder=""
                                            readonly="true"
                                            [value]="feed.mainFeed.name" />
                                    </mat-form-field>
                                    <ui-button
                                        class="tw-mt-3"
                                        data-testid="button-edit-feed"
                                        svgIcon="edit"
                                        text="Make changes in fields & operations"
                                        (click)="openEditor()"></ui-button>
                                </div>
                                <div class="tw-px-2.5">
                                    <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                                        <mat-form-field>
                                            <mat-label>Update Interval</mat-label>
                                            <mat-select
                                                [value]="feed.mainFeed!.updateInterval"
                                                #intervalSelect>
                                                @if (readableUpdateIntervals()) {
                                                    @for (interval of readableUpdateIntervals(); track interval) {
                                                        <mat-option [value]="interval.value">{{
                                                            interval.label
                                                        }}</mat-option>
                                                    }
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                        <ui-button
                                            [disabled]="intervalSelect.value === feed.mainFeed.updateInterval"
                                            class="tw-h-10"
                                            type="primary"
                                            (click)="updateInterval(feed.mainFeed, intervalSelect.value)"
                                            text="Update interval"></ui-button>
                                    </div>
                                    <div class="w-full">
                                        <p class="tw-mb-5">
                                            Specify how often the feed should be fetched from the server and updated.
                                            Try to avoid picking an interval with an unnecessary short timespan.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ui-tab>
        </ui-tabs>
    </ui-body>
}
