@let data = viewData();
@let feedForm = data?.feedForm;
@let containsCreativeField = data?.containsCreativeField;

<header-navigation>
    <ui-button
        slot="start"
        text="CLOSE WITHOUT SAVING"
        [routerLink]="['../']"></ui-button>
    @if (feedForm) {
        <ui-button
            slot="end"
            type="primary"
            text="SAVE AND CLOSE"
            [loading]="loading()"
            (click)="saveMainFeed(feedForm)">
        </ui-button>
    }
</header-navigation>
<ui-body
    [full]="true"
    class="ui-body">
    <ui-tabs>
        <ui-tab
            name="Fields"
            [selected]="selectedTab() === 'fields'"
            (selectedChange)="tabChange('Fields', $event)">
            @if (feedForm) {
                <template-fields [feedForm]="feedForm" />
            }
        </ui-tab>
        @if (containsCreativeField) {
            <ui-tab
                name="Creatives"
                [selected]="selectedTab() === 'creatives'"
                (selectedChange)="tabChange('Creatives', $event)">
                @if (feedForm) {
                    <default-creative-card
                        [creativeField]="feedForm.controls.blueprintFields | firstcreativefield"
                        [rootArrayPath]="feedForm.controls.rootArrayPath.value" />
                }
            </ui-tab>
        }
        <ui-tab
            name="Operations"
            [selected]="selectedTab() === 'operations'"
            (selectedChange)="tabChange('Operations', $event)">
            @if (feedForm) {
                <filters
                    [filterForm]="feedForm.controls.filterGroups"
                    [rootArrayPath]="feedForm.controls.rootArrayPath.value" />
            }
        </ui-tab>
    </ui-tabs>
</ui-body>
