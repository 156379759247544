import { Component } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';

@Component({
  selector: 'skeleton-select-field',
  imports: [UIModule, MatIconModule, MatFormFieldModule, MatSelectModule],
  templateUrl: './skeleton-select-field.component.html',
  styleUrl: './skeleton-select-field.component.scss'
})
export class SkeletonSelectFieldComponent {
  rows = Array.from({ length: 8 });
}
