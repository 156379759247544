import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnDestroy, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { catchError, map, mergeMap, Observable, of, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { FeedForm } from 'src/app/models/feed-form.model';
import { CreativeDynamicField, FeedFieldType, MainFeed } from 'src/app/models/feed.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { FiltersComponent } from 'src/app/shared/filters/filters.component';
import { convertBaseFieldGroupToFeedField, convertFilterGroupFormToFilterGroups } from 'src/app/utils/main-feed-helper';
import { FirstcreativefieldPipe } from '../../../pipes/first-creative-field.pipe';
import { BottomSheetPreviewComponent } from '../../../shared/bottom-sheet-preview/bottom-sheet-preview.component';
import { DefaultCreativeCardComponent } from '../../../shared/default-creative-card/default-creative-card.component';
import { HeaderNavigationComponent } from '../../../shared/header-navigation/header-navigation.component';
import { TemplateFieldsComponent } from '../../stepper/select-fields/template-fields/template-fields.component';

@Component({
    selector: 'editable-feed',
    imports: [
        UIModule,
        CommonModule,
        TemplateFieldsComponent,
        RouterLink,
        HeaderNavigationComponent,
        DefaultCreativeCardComponent,
        FiltersComponent,
        FirstcreativefieldPipe
    ],
    templateUrl: './editable-feed.component.html',
    styleUrl: './editable-feed.component.scss'
})
export class EditableFeedComponent implements OnDestroy {
    private mainFeedService = inject(MainFeedService);
    private activatedRoute = inject(ActivatedRoute);
    private templateBuilderService = inject(TemplateBuilderService);
    private uiNotificationService = inject(UINotificationService);
    private studioService = inject(StudioService);
    private draggableBottomSheetService = inject(DraggableBottomSheetService);
    private feedSourceService = inject(FeedSourceService);
    private previewService = inject(PreviewService);
    private router = inject(Router);
    private destroyRef = inject(DestroyRef);

    loading = signal<boolean>(false);
    toggleCreativeTab = false;
    mainFeedData$ = this.fetchMainFeedByRouteId().pipe(shareReplay(1));

    viewData = toSignal(
        this.mainFeedData$.pipe(
            tap(mainFeed => {
                this.feedSourceService.setFeedSourceUrl(mainFeed.sourceUrl);
            }),
            mergeMap(mainFeed => this.feedSourceService.getJsonSchema().pipe(map(() => mainFeed))),
            switchMap(mainFeed => {
                const creativeField = mainFeed.blueprint.fields.find(field => field.$type === FeedFieldType.Creative);
                if (creativeField && creativeField.creativeId) {
                    // We assume there is always only one creative field for now
                    return this.studioService.getDynamicFieldsByCreativeId(creativeField.creativeId).pipe(
                        map(dynamicFields => {
                            return {
                                ...mainFeed,
                                blueprint: {
                                    ...mainFeed.blueprint,
                                    fields: mainFeed.blueprint.fields.map(field => {
                                        if (field.$type === FeedFieldType.Creative) {
                                            return {
                                                ...field,
                                                mappings: dynamicFields.map(dynamicField => {
                                                    const mapping = field.mappings.find(
                                                        mapping => mapping.dynamicPropertyId === dynamicField.id
                                                    );

                                                    if (!mapping) {
                                                        const newMapping: CreativeDynamicField = {
                                                            $type: FeedFieldType.Path,
                                                            path: '',
                                                            dynamicPropertyId: dynamicField.id,
                                                            dynamicPropertyLabel: dynamicField.label,
                                                            dynamicPropertyType: dynamicField.type
                                                        };
                                                        return newMapping;
                                                    } else {
                                                        return {
                                                            ...mapping,
                                                            dynamicPropertyLabel: dynamicField.label,
                                                            dynamicPropertyType: dynamicField.type
                                                        };
                                                    }
                                                })
                                            };
                                        } else {
                                            return field;
                                        }
                                    })
                                }
                            };
                        })
                    );
                } else {
                    return of(mainFeed);
                }
            }),
            switchMap(mainFeed => {
                const feedForm = this.templateBuilderService.buildForm(mainFeed);

                return feedForm.valueChanges.pipe(
                    startWith(feedForm.value),
                    switchMap(_ => {
                        const fields = convertBaseFieldGroupToFeedField(feedForm.controls.blueprintFields);
                        const filters = convertFilterGroupFormToFilterGroups(feedForm.controls.filterGroups);
                        const containsCreativeField = fields.some(field => field.$type === FeedFieldType.Creative);

                        const result = { feedForm, containsCreativeField };

                        return feedForm.valid
                            ? this.previewService
                                  .getPreviewData({
                                      source: feedForm.controls.sourceUrl.value,
                                      fields,
                                      filters,
                                      rootArrayPath: feedForm.controls.rootArrayPath.value
                                  })
                                  .pipe(
                                      map(() => result),
                                      catchError(() => of(result))
                                  )
                            : of(result);
                    })
                );
            })
        )
    );

    selectedTab = toSignal(this.activatedRoute.queryParams.pipe(map(params => params['tab'])));

    saveMainFeed(feedForm: FeedForm): void {
        this.loading.set(true);
        const mainFeed: MainFeed = {
            name: feedForm.controls.template.controls.name.value,
            sourceUrl: feedForm.controls.sourceUrl.value,
            updateInterval: feedForm.controls.updateInterval.value,
            blueprint: {
                fields: convertBaseFieldGroupToFeedField(feedForm.controls.blueprintFields)
            },
            rootArrayPath: feedForm.controls.rootArrayPath.value || '',
            filters: { filterGroups: convertFilterGroupFormToFilterGroups(feedForm.controls.filterGroups) }
        };

        this.mainFeedService.mainFeedId$
            .pipe(
                switchMap(mainFeedId => this.mainFeedService.updateMainFeed({ ...mainFeed, id: mainFeedId })),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: () => {
                    this.uiNotificationService.open('Saved successfully', {
                        type: 'success',
                        placement: 'top',
                        autoCloseDelay: 3000
                    });
                    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
                    this.loading.set(false);
                },
                error: () => {
                    this.loading.set(false);
                }
            });
    }

    operationsTabSelected(selected: boolean): void {
        if (selected) {
            const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
            this.draggableBottomSheetService.createBottomSheet(componentPortal);
        } else {
            this.draggableBottomSheetService.removeBottomSheet();
        }
    }

    tabChange(tab: 'Creatives' | 'Fields' | 'Operations', selected: boolean): void {
        if (!selected) {
            return;
        }
        this.draggableBottomSheetService.removeBottomSheet();

        switch (tab) {
            case 'Creatives':
                this.router.navigate([], { queryParams: { tab: 'creatives' }, relativeTo: this.activatedRoute });
                break;
            case 'Fields':
                this.router.navigate([], { queryParams: { tab: 'fields' }, relativeTo: this.activatedRoute });
                break;
            case 'Operations':
                const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
                this.draggableBottomSheetService.createBottomSheet(componentPortal);
                this.router.navigate([], { queryParams: { tab: 'operations' }, relativeTo: this.activatedRoute });
                break;
        }
    }

    private fetchMainFeedByRouteId(): Observable<Required<MainFeed>> {
        return this.activatedRoute.params.pipe(
            switchMap(params => {
                this.mainFeedService.setMainFeedId(params['id']);
                return this.mainFeedService.getMainFeedById(params['id']);
            })
        );
    }

    ngOnDestroy(): void {
        this.draggableBottomSheetService.removeBottomSheet();
    }
}
