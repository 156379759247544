import { KeyValuePipe } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { CreativeFieldMapping } from 'src/app/models/feed-form.model';
import { FeedFieldType } from 'src/app/models/feed.model';
import { PathFieldComponent } from 'src/app/shared/path-field/path-field.component';
import { StaticTextFieldComponent } from 'src/app/shared/static-text-field/static-text-field.component';
import { SelectTypeComponent } from 'src/app/shared/select-type/select-type.component';
import { ExpressionFieldComponent } from '../expression-field/expression-field.component';

@Component({
    selector: 'creative-mappings-form',
    imports: [
        PathFieldComponent,
        StaticTextFieldComponent,
        SelectTypeComponent,
        MatIcon,
        KeyValuePipe,
        ExpressionFieldComponent
    ],
    templateUrl: './creative-mappings-form.component.html',
    styleUrl: './creative-mappings-form.component.scss'
})
export class CreativeMappingsFormComponent {
    creativeMappingsForm = input.required<FormArray<CreativeFieldMapping>>();
    rootArrayPath = input<string>('');

    FeedFieldType = FeedFieldType;

    resetFieldValue(control: FormControl<string>): void {
        control.setValue('');
    }
}
