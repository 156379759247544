import { SelectionModel } from '@angular/cdk/collections';
import { CdkTree, CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, inject, input, OnInit, output, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UIModule, UITooltipDirective } from '@bannerflow/ui';
import { JsonSchema } from 'json-schema-library';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';

@Component({
    selector: 'json-schema-tree',
    templateUrl: './json-schema-tree.component.html',
    styleUrls: ['./json-schema-tree.component.scss'],
    imports: [
        CommonModule,
        CdkTreeModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        UITooltipDirective,
        UIModule
    ]
})
export class JsonSchemaTreeComponent implements OnInit {
    protected readonly Object = Object;
    private feedSourceService = inject(FeedSourceService);

    jsonSchema = input.required<JsonSchema>();
    onlyArraySelection = input<boolean>(false);
    pathSelected = output<string>();
    tree = viewChild<CdkTree<JsonSchema>>(CdkTree);

    childrenAccessor = (node: JsonSchema) => {
        if (node['type'] === 'array') {
            const items = Array.isArray(node['items']) ? node['items'][0] : node['items'];
            if (items?.['properties']) {
                return Object.entries(items['properties']).map(([key, value]) => {
                    if (typeof value === 'object') {
                        return {
                            ...value,
                            name: key
                        };
                    }
                    return {
                        ...value,
                        name: key
                    };
                });
            }
            return items ? [items] : [];
        }
        if (node['properties']) {
            return Object.entries(node['properties']).map(([key, value]) => {
                if (typeof value === 'object') {
                    return {
                        ...value,
                        name: key
                    };
                }
                return {
                    ...value,
                    name: key
                };
            });
        }
        return [];
    };

    hasChild = (_: number, node: JsonSchema) => {
        return (node['type'] === 'array' && !!node['items']) || (node['type'] === 'object' && !!node['properties']);
    };

    dataSource: JsonSchema[] = [];

    selection = new SelectionModel<JsonSchema>(false, []);

    ngOnInit() {
        this.getTreeData();
    }

    getTreeData() {
        const schema = this.jsonSchema();
        if (!schema) {
            console.error('JsonSchema is undefined');
            return;
        }

        if (schema['type'] === 'array') {
            const items = Array.isArray(schema['items']) ? schema['items'][0] : schema['items'];
            if (items?.['properties']) {
                this.dataSource = Object.entries(items['properties']).map(([key, value]) => {
                    if (typeof value === 'object') {
                        return {
                            ...value,
                            name: key
                        };
                    }
                    return {
                        ...value,
                        name: key
                    };
                });
            } else if (items) {
                this.dataSource = [items];
            }
        } else if (schema['properties']) {
            this.dataSource = Object.entries(schema['properties']).map(([key, value]) => {
                if (typeof value === 'object') {
                    return {
                        ...value,
                        name: key
                    };
                }
                return {
                    ...value,
                    name: key
                };
            });
        }
    }

    toggleNode(node: JsonSchema) {
        const isSelected = this.selection.isSelected(node);

        if (isSelected) {
            this.selection.clear();
            this.pathSelected.emit('');
        } else {
            if (this.onlyArraySelection()) {
                this.feedSourceService.setSubFeedSchema(node['pointer']);
            }
            this.selection.select(node);
            this.pathSelected.emit(node['jmesPath']);
        }
    }

    getExampleTooltip(examples: string[]): string {
        if (!examples?.length) return '';
        const formattedExamples = examples
            .slice(0, 3)
            .map(ex => `"${ex}"`)
            .join(', ');
        return `Examples: ${formattedExamples}${examples.length > 3 ? ', ...' : ''}`;
    }
}
