import { Component } from '@angular/core';
import { PreviewTableComponent } from '../../views/stepper/select-fields/preview-table/preview-table.component';
import { DraggableBottomSheetComponent } from 'src/app/shared/draggable-bottom-sheet/draggable-bottom-sheet.component';

@Component({
    selector: 'bottom-sheet-preview',
    imports: [PreviewTableComponent, DraggableBottomSheetComponent],
    templateUrl: './bottom-sheet-preview.component.html',
    styleUrl: './bottom-sheet-preview.component.scss'
})
export class BottomSheetPreviewComponent {}
