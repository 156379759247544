import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
@Component({
    selector: 'connect-feed',
    imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, UIModule],
    templateUrl: './connect-feed.component.html',
    styleUrls: ['./connect-feed.component.scss']
})
export class ConnectFeedComponent {
    sourceUrlControl = input.required<FormControl<string>>();

    autoFillInput() {
        this.sourceUrlControl().setValue('https://bffeedsv2testst.blob.core.windows.net/feeds/iGaming-1.json');
    }
}
