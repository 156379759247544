import { Pipe, PipeTransform } from '@angular/core';
import { FeedField } from '../models/feed.model';

@Pipe({
    name: 'extractNames'
})
export class ExtractNamesPipe implements PipeTransform {
    transform(items: FeedField[]): string {
        if (!items || !Array.isArray(items)) {
            return '';
        }

        return items
            .map(item => item.name)
            .filter(name => name)
            .join(', ');
    }
}
