@if (catalog$ | async; as catalog) {
    <div
        class="tw-w-[286px] tw-p-4 tw-h-[160px] tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-bg-white tw-flex tw-flex-col tw-justify-between">
        <div class="tw-flex tw-flex-col">
            <h1 class="tw-mb-0">Published Catalog</h1>
            <div class="tw-flex tw-items-center tw-my-2">
                <p class="tw-my-0">as &nbsp;</p>
                <h4 class="tw-my-0">{{ catalog.name }}</h4>
            </div>
        </div>
        <div class="tw-flex tw-justify-between tw-items-center">
            <ui-button
                [svgIcon]="'cloud-l-negative'"
                (click)="onUpdateCatalogClicked(catalog)"
                type="primary"
                text="Update catalogue" />
            <a
                [href]="'https://business.facebook.com/commerce/catalogs/' + catalog.externalId + '/products'"
                target="_blank"
                ><ui-svg-icon
                    class="tw-cursor-pointer tw-text-ui-primary icon-go-to-url"
                    [icon]="'go-to-url'"
            /></a>
        </div>
    </div>
} @else {
    <div
        class="tw-w-[286px] tw-h-[160px] tw-p-4 tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-bg-white">
        <h1>Ready for publish</h1>
        <ui-button
            (click)="publishCatalogDialog.open()"
            type="primary"
            text="Publish as catalog" />
    </div>
}

<ui-dialog
    #publishCatalogDialog="ui-dialog"
    [config]="{ headerText: 'Publish as Catalogue', width: '100%', maxHeight: 600, padding: 0, maxWidth: '640px' }">
    <ng-template ui-dialog-template>
        <publish-catalog
            (close)="publishCatalogDialog.close()"
            (catalogPublished)="catalogCreated()"></publish-catalog>
    </ng-template>
</ui-dialog>
