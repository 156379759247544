import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { FFFeatureFlagsDirective } from '@bannerflow/feature-flags';
import { NSNotificationsComponent } from '@bannerflow/notification';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { map, Observable, switchMap, take } from 'rxjs';
import { FeatureFlags } from 'src/app/models/feature-flags.model';
import { Filter, FilterGroup, FilterGroups } from 'src/app/models/filters.model';
import { OperationsSummary, OperationsType } from 'src/app/models/operations.model';
import { ExtractNamesPipe } from 'src/app/pipes/extract-names.pipe';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { NavigationService } from 'src/app/services/api/navigation.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { CardSummaryComponent } from 'src/app/shared/card-summary/card-summary.component';
import { HeaderNavigationComponent } from 'src/app/shared/header-navigation/header-navigation.component';
import { OperationsSummaryComponent } from 'src/app/shared/operations-summary/operations-summary.component';
import { readableUpdateIntervals } from 'src/app/utils/main-feed-helper';
import { PublishMetaCatalogComponent } from 'src/app/views/feed-editor/publish-meta-category/publish-meta-category.component';
import { FeedItem, FeedUpdateInterval, MainFeed } from '../../models/feed.model';
import { MainFeedService } from '../../services/api/main-feed.service';
import { PreviewCarouselComponent } from './preview-carousel/preview-carousel.component';

interface PreviewBlueprint {
    data: FeedItem[];
    filters: FilterGroups;
}

@Component({
    selector: 'feed-editor',
    templateUrl: './feed-editor.component.html',
    styleUrl: './feed-editor.component.scss',
    imports: [
        CommonModule,
        UIModule,
        CardSummaryComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        PreviewCarouselComponent,
        OperationsSummaryComponent,
        HeaderNavigationComponent,
        PublishMetaCatalogComponent,
        NSNotificationsComponent,
        FFFeatureFlagsDirective,
        ExtractNamesPipe
    ]
})
export class FeedEditorComponent {
    private activatedRoute = inject(ActivatedRoute);
    private mainFeedService = inject(MainFeedService);
    private mainFilterService = inject(MainFilterService);
    private previewService = inject(PreviewService);
    private notificationService = inject(UINotificationService);
    private readonly router = inject(Router);
    private navigationService = inject(NavigationService);

    readableUpdateIntervals = signal(readableUpdateIntervals());

    editableFeed = toSignal(
        this.activatedRoute.params.pipe(
            map(params => params['id']),
            switchMap(id =>
                this.mainFeedService.getMainFeedById(id).pipe(
                    switchMap(mainFeed => {
                        return this.fetchBlueprintPreview(mainFeed).pipe(
                            map(blueprintPreview => ({
                                mainFeed,
                                previewData: blueprintPreview.data ?? [],
                                operationsSummary: this.mapFiltersToOperationsSummary(
                                    blueprintPreview.filters?.filterGroups ?? []
                                )
                            }))
                        );
                    })
                )
            )
        )
    );

    FeatureFlags = FeatureFlags;

    updateInterval(mainFeed: MainFeed, updatedInterval: FeedUpdateInterval) {
        const updatedFeed = { ...mainFeed, updateInterval: updatedInterval };
        this.mainFeedService
            .updateMainFeed(updatedFeed)
            .pipe(take(1))
            .subscribe(() => {
                mainFeed.updateInterval = updatedInterval;

                this.notificationService.open('Feed interval updated', {
                    type: 'success',
                    autoCloseDelay: 3000,
                    placement: 'top'
                });
            });
    }

    openEditor(): void {
        this.router.navigate(['./edit'], { relativeTo: this.activatedRoute, queryParamsHandling: 'preserve' });
    }

    private fetchBlueprintPreview(feed: Required<MainFeed>): Observable<PreviewBlueprint> {
        return this.mainFilterService.getFiltersById(feed.id).pipe(
            switchMap(filters => {
                const previewBlueprintRequest = {
                    id: feed.id,
                    source: feed.sourceUrl,
                    fields: feed.blueprint.fields,
                    filters: Array.isArray(filters) ? filters : [],
                    rootArrayPath: feed.rootArrayPath
                };
                return this.previewService
                    .getPreviewData(previewBlueprintRequest)
                    .pipe(map(previewBlueprint => ({ data: previewBlueprint.data, filters })));
            }),
            take(1)
        );
    }

    private mapFiltersToOperationsSummary(filterGroups: FilterGroup[]): OperationsSummary[] {
        return filterGroups.map(filter => ({
            label: filter.label || '',
            type: OperationsType.Filter,
            summary: this.generateSummary(filter)
        }));
    }

    private generateSummary(filterGroup: FilterGroup): string[] {
        return filterGroup.filters.map(
            (filter: Filter) =>
                `<div>Filter: <strong>${this.capitalizeFirstLetter(filter.field)}</strong> field ${filter.operator.toLowerCase()} <strong>"${filter.arguments.toString()}"</strong></div>`
        );
    }

    private capitalizeFirstLetter(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    goToBFCListUrl(): void {
        this.navigationService.navigateToBFCListUrl().pipe(take(1)).subscribe();
    }

    copyURL(event: Event, sourceUrl: string): void {
        event.preventDefault();
        navigator.clipboard.writeText(sourceUrl);
    }
}
