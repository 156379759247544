import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterGroups } from '../../models/filters.model';

interface FilterGroupsResponse {
    filterGroups: FilterGroups;
}
@Injectable({
    providedIn: 'root'
})
export class MainFilterService {
    private http = inject(HttpClient);
    private BASE_URL = `${environment.origins.feedsSupremeCommander}/api/MainFeeds`;

    getFiltersById(feedId: string): Observable<FilterGroups> {
        const url = `${this.BASE_URL}/${feedId}/filters`;
        return this.http.get<FilterGroupsResponse>(url).pipe(map(val => val.filterGroups));
    }
}
