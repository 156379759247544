import { ApplicationConfig, ErrorHandler } from '@angular/core';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { bfHeaderInterceptor } from './interceptors/bf-header.interceptor';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideSentinelService, withLoggerConfig, withNewRelic } from '@bannerflow/sentinel';
import { NewRelicPlugin } from 'src/plugins/newrelic';
import { provideNotificationService } from '@bannerflow/notification';
import { BehaviorSubject } from 'rxjs';
import { provideFeatureFlags } from '@bannerflow/feature-flags';
import { GlobalErrorHandler } from 'src/plugins/global-error-handler.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideSentinelService(
            withNewRelic({
                enabled: environment.stage !== 'local' && environment.stage !== 'proxy',
                ...NewRelicPlugin.init(environment.stage === 'production', [...Object.values(environment.origins)])
            }),
            withLoggerConfig({
                level: 'debug',
                production: environment.stage === 'production',
                sandbox: environment.stage === 'sandbox',
                stage: environment.stage
            })
        ),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        provideAnimations(),
        provideRouter(routes),
        provideHttpClient(withInterceptors([bfHeaderInterceptor, authHttpInterceptorFn])),
        provideNotificationService({
            apiUri: environment.origins.notificationServiceApi,
            accessToken$: new BehaviorSubject<string | undefined>(undefined),
            signalR: {
                enabled: true,
                url: environment.origins.notificationServiceHub
            }
        }),
        provideAuth0({
            clientId: environment.auth.clientId,
            domain: environment.auth.domain,
            authorizationParams: {
                redirect_uri: window.location.search.includes('branch=')
                    ? `${window.location.origin}/${window.location.search}`
                    : `${window.location.origin}`,
                audience: environment.auth.audience,
                scope: 'email listservice feedssupremecommander feedsdeliverymaster feedssocializingmaster feedsfearsomeprocessor studio bannerflow profile socialaccountservice'
            },
            httpInterceptor: {
                allowedList: Object.values(environment.origins).map(origin => ({
                    uri: `${origin}/*`,
                    tokenOptions: {
                        authorizationParams: {
                            audience: environment.auth.audience
                        }
                    }
                }))
            },
            useRefreshTokens: true
        }),
        provideFeatureFlags({
            enabled: true,
            appName: 'feeds-client',
            url: environment.origins.featureFlagService,
            clientKey: environment.featureFlags.clientKey
        }),
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline', subscriptSizing: 'dynamic' }
        }
    ]
};
