import { Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { fromEvent, debounceTime, map, startWith, pairwise, filter, Subscription } from 'rxjs';

@Directive({
    selector: '[infiniteScroll]'
})
export class InfiniteScrollDirective implements OnDestroy {
    @Output() scrolledToBottom = new EventEmitter<void>();
    private scrollSubscription: Subscription;

    constructor(private el: ElementRef) {
        this.scrollSubscription = fromEvent(this.el.nativeElement, 'scroll')
            .pipe(
                debounceTime(200),
                map(() => this.el.nativeElement.scrollTop + this.el.nativeElement.clientHeight),
                startWith(0),
                pairwise(),
                filter(([prev, curr]) => {
                    const threshold = 50; // pixels from the bottom to trigger the event
                    const isNearBottom = curr >= this.el.nativeElement.scrollHeight - threshold;
                    return isNearBottom && prev < curr; // Emit only if we're near the bottom and moving downwards
                })
            )
            .subscribe(() => this.scrolledToBottom.emit());
    }

    ngOnDestroy() {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }
}
