import { Component, inject, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { CreativeMetaData } from 'src/app/models/feed-form.model';

@Component({
    selector: 'creative-field',
    imports: [UIModule],
    templateUrl: './creative-field.component.html',
    styleUrl: './creative-field.component.scss'
})
export class CreativeFieldComponent {
    creativeGroup = input.required<CreativeMetaData>();
    canConnectCreative = input.required<boolean>();

    router = inject(Router);
    activatedRoute = inject(ActivatedRoute);
    creativeText = 'The creative can be connected in the next step';

    selectCreativeTab(): void {
        this.router.navigate([], { queryParams: { tab: 'creatives' }, relativeTo: this.activatedRoute });
    }
}
