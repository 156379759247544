import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIModule } from '@bannerflow/ui';
import { ExpressionFieldEditorComponent } from 'src/app/shared/expression-field/expression-field-editor/expression-field-editor.component';

@Component({
    selector: 'expression-field',
    imports: [UIModule, CommonModule, ExpressionFieldEditorComponent, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
    templateUrl: './expression-field.component.html',
    styleUrl: './expression-field.component.scss'
})
export class ExpressionFieldComponent {
    fieldName = input.required<string>();
    expressionFormControl = input.required<FormControl<string>>();
    fieldId = input.required<string>();
    rootArrayPath = input<string>('');
}
