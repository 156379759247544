import { Component, effect, input, signal, viewChild } from '@angular/core';
import { CREATIVE_PREVIEW_HOST, IDynamicPropertyData, UICreativePreviewComponent, UIModule } from '@bannerflow/ui';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'creative-preview',
    imports: [UIModule, UICreativePreviewComponent],
    providers: [{ provide: CREATIVE_PREVIEW_HOST, useValue: environment.origins.creativePreviewUrl }],
    templateUrl: './creative-preview.component.html',
    styleUrl: './creative-preview.component.scss'
})
export class CreativePreviewComponent {
    readonly creativePreview = viewChild.required(UICreativePreviewComponent);
    selectedCreative = input.required<{ creativeId: string; creativeSetId: string }>();
    dynamicPropertyData = input<IDynamicPropertyData[]>();

    private isAdRendered = signal(false);

    adRendered() {
        this.isAdRendered.set(true);
    }

    constructor() {
        effect(() => {
            if (this.isAdRendered() && this.dynamicPropertyData() && this.creativePreview()) {
                this.creativePreview().renderDynamicProperties(this.dynamicPropertyData() || []);
            }
        });
    }
}
