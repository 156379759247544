import { OverlayModule } from '@angular/cdk/overlay';
import { Component, computed, inject, input, output, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { fromEvent, map, startWith } from 'rxjs';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { JsonSchemaTreeComponent } from '../json-schema-tree/json-schema-tree.component';
import { PathFieldEditorComponent } from './path-field-editor/path-field-editor.component';
import { JsonSchema } from 'json-schema-library';
import { removeRootArrayPathFromPath } from 'src/app/utils/format-path';

@Component({
    selector: 'path-field',
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
        UIModule,
        OverlayModule,
        PathFieldEditorComponent,
        JsonSchemaTreeComponent
    ],
    templateUrl: './path-field.component.html',
    styleUrl: './path-field.component.scss'
})
export class PathFieldComponent {
    pathFieldControl = input.required<FormControl<string>>();
    fieldName = input.required<string>();
    fieldId = input<string>();
    rootArrayPath = input.required<string>();
    chipClicked = output<void>();

    fieldEditorDialog = viewChild.required<UIDialogDirective>('fieldEditorDialog');

    feedSourceService = inject(FeedSourceService);

    isOpen = false;
    feedSchema: JsonSchema;

    windowResize = toSignal(
        fromEvent(window, 'resize').pipe(
            startWith(window.innerHeight),
            map(() => window.innerHeight)
        ),
        { initialValue: window.innerHeight }
    );
    overlayHeight = computed(() => {
        return Math.min(this.windowResize() * 0.9, 500);
    });

    ngOnInit() {
        this.feedSchema = this.feedSourceService.getFeedSchema();
    }

    add(event: MatChipInputEvent): void {
        this.setChipValue(event.value);
        event.chipInput!.clear();
    }

    setChipValue(value: string): void {
        if (value == '') throw new Error('Empty chip value is not allowed');

        value = value.trim();

        this.pathFieldControl().setValue(value);
    }

    removeField() {
        this.pathFieldControl().setValue('');
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }

    openFieldEditor(event: MouseEvent) {
        event.stopPropagation();
        this.fieldEditorDialog().open();
    }

    onPathSelected(path: string) {
        if (this.rootArrayPath()) {
            const rootArrayPath = this.rootArrayPath();
            const cleanedPath = removeRootArrayPathFromPath(path, rootArrayPath);

            this.pathFieldControl().setValue(cleanedPath);
        } else {
            this.pathFieldControl().setValue(path);
        }
    }
}
