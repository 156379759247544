import { Component, computed, effect, input, signal, ViewChild } from '@angular/core';
import { CREATIVE_PREVIEW_HOST, IDynamicPropertyData, UICreativePreviewComponent, UIModule } from '@bannerflow/ui';
import { Creative } from 'src/app/models/creative.model';
import { environment } from 'src/environments/environment';
import { CreativePreviewComponent } from '../creative-preview/creative-preview.component';
import { calculateScaledDimensions } from 'src/app/utils/calculations';
import { NgStyle } from '@angular/common';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'paginated-creative-preview',
    imports: [CommonModule, UIModule, CreativePreviewComponent, NgStyle],
    providers: [{ provide: CREATIVE_PREVIEW_HOST, useValue: environment.origins.creativePreviewUrl }],
    templateUrl: './paginated-creative-preview.component.html',
    styleUrl: './paginated-creative-preview.component.scss'
})
export class PaginatedCreativePreviewComponent {
    @ViewChild(UICreativePreviewComponent) creativePreview: UICreativePreviewComponent;

    creative = input.required<Creative>();
    dynamicPropertyData = input.required<IDynamicPropertyData[][]>();

    currentPage = signal(1);
    currentPreviewData = computed(() => {
        return this.dynamicPropertyData()[this.currentPage() - 1];
    });
    creativePreviewContainerSize = computed(() => {
        // Normal preview size
        return calculateScaledDimensions(this.creative().size.width, this.creative().size.height, 400);
    });

    dialogPreviewSize = computed(() => {
        // Calculate size to fit in dialog while maintaining aspect ratio
        const verticalPadding = 180; // Space for header and navigation
        const horizontalPadding = 64; // Small padding on sides
        const maxHeight = window.innerHeight - verticalPadding;
        const maxWidth = Math.min(window.innerWidth - horizontalPadding, window.innerHeight * 1.2); // Limit width relative to height

        // Get the original dimensions as numbers
        const originalWidth = Number(this.creative().size.width);
        const originalHeight = Number(this.creative().size.height);

        // Calculate the scaling factor to fit the window
        const scaleWidth = maxWidth / originalWidth;
        const scaleHeight = maxHeight / originalHeight;
        const scale = Math.min(scaleWidth, scaleHeight);

        // Return the scaled dimensions as numbers
        return {
            width: Math.floor(originalWidth * scale),
            height: Math.floor(originalHeight * scale)
        };
    });

    constructor() {
        effect(() => {
            // If the creative changes, reset to page 1
            this.creative();
            this.currentPage.set(1);
        });
    }

    previous(): void {
        if (this.currentPage() > 1) {
            this.currentPage.update(page => page - 1);
        }
    }

    next(): void {
        if (this.currentPage() < this.dynamicPropertyData().length) {
            this.currentPage.update(page => page + 1);
        }
    }

    pageChange(page: number): void {
        if (page >= 1 && page <= this.dynamicPropertyData().length) {
            this.currentPage.update(() => page);
        }
    }
}
