@let currentPage = (currentPage$ | async) || 1;
@let previewData = (previewData$ | async) || { data: [], total: 0 };

<div class="tw-px-13">
    <div class="tw-w-full tw-grid tw-grid-cols-3 tw-auto-rows-auto tw-gap-y-0">
        <div>
            <editable-field [formCtrl]="feedForm().controls.template.controls.name">
                <h3>
                    {{ feedForm().controls.template.controls.name.value }}
                </h3>
            </editable-field>
            <h3 class="tw-text-ui-primary">Template fields</h3>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-end">
            <h3 class="tw-text-ui-primary">Imported fields from your source</h3>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-end tw-pl-2">
            <div class="tw-flex tw-justify-between">
                <h3 class="tw-text-ui-primary">Values from your source</h3>
                @if (previewData.total > 1) {
                    <div class="tw-flex tw-flex-row tw-gap-1 tw-items-center">
                        <ui-button
                            (click)="previewPrevious(currentPage)"
                            ui-theme="minimal"
                            [svgIcon]="'arrow-left'"
                            [disabled]="currentPage === 1"></ui-button>
                        <p>{{ currentPage }} / {{ previewData.total }}</p>
                        <ui-button
                            (click)="previewNext(currentPage)"
                            ui-theme="minimal"
                            [svgIcon]="'arrow-right'"
                            [disabled]="currentPage >= previewData.total"></ui-button>
                    </div>
                }
            </div>
        </div>
    </div>

    <form [formGroup]="feedForm()">
        <div class="tw-grid tw-grid-cols-3">
            @if (feedForm().controls.blueprintFields.controls.length > 0) {
                <dynamic-form
                    [ngClass]="previewData.total === 0 ? 'tw-col-span-2' : 'tw-col-span-3'"
                    [bluePrintFieldsFormAry]="feedForm().controls.blueprintFields"
                    [rootArrayPath]="feedForm().controls.rootArrayPath.value">
                    @if (previewData.total > 0) {
                        <ng-template
                            let-fieldname
                            #header>
                            @let previewValue =
                                previewData.data[currentPage - 1].data[fieldname] === undefined
                                    ? ''
                                    : previewData.data[currentPage - 1].data[fieldname] === null
                                      ? 'No data found'
                                      : previewData.data[currentPage - 1].data[fieldname];
                            <preview-value
                                class="tw-min-w-0 tw-px-2"
                                [previewValue]="previewValue" />
                        </ng-template>
                    }
                </dynamic-form>
            }
            @if (previewData.total === 0) {
                <div class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-3">
                    Data preview will become available as soon as you start mapping your fields.
                </div>
            }
        </div>
    </form>

    <div class="tw-flex tw-justify-between tw-items-center tw-mt-5">
        <add-field
            (addNewField)="createNewField($event)"
            [selectedOptionalFields]="nonRequiredFields()"
            [template]="{
                id: feedForm().controls.template.controls.id.value,
                label: '',
                type: 'MetaTemplate',
                useAI: false
            }" />
    </div>
</div>
