<div class="tw-flex tw-justify-between tw-items-center tw-w-[400px] tw-flex-col">
    <div
        class="tw-border-solid tw-border-ui-grey-89 tw-border tw-relative"
        [ngStyle]="{
            width: creativePreviewContainerSize().width,
            height: creativePreviewContainerSize().height
        }">
        <creative-preview
            [selectedCreative]="{ creativeId: creative().id, creativeSetId: creative().creativeSetId }"
            [dynamicPropertyData]="currentPreviewData()" />

        <div
            class="tw-absolute tw-top-0 tw-right-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-end tw-cursor-pointer tw-group">
            <ui-button
                class="tw-mb-8 group-hover:tw-opacity-100 tw-opacity-0 tw-transition-opacity tw-duration-200 tw-ease-in-out"
                (click)="previewDialog.open()"
                text="Full-screen preview"
                [svgIcon]="'view-expand'">
            </ui-button>
        </div>
    </div>

    @if (dynamicPropertyData().length > 0) {
        <div class="tw-mt-2 tw-flex tw-justify-between tw-w-[400px]">
            <p class="tw-text-sm tw-text-gray-600 tw-mb-1">Preview other values</p>
            <div class="tw-flex tw-items-center tw-gap-0">
                <ui-button
                    (click)="previous()"
                    ui-theme="minimal"
                    [svgIcon]="'arrow-left'"
                    class="tw-bg-gray-100"
                    [disabled]="currentPage() === 1">
                </ui-button>
                <ui-number-input
                    class="tw-w-8 tw-text-center tw-mx-1"
                    ui-theme="minimal"
                    [min]="1"
                    [max]="dynamicPropertyData().length"
                    (valueChange)="pageChange($event)"
                    [value]="currentPage()" />
                <span class="tw-text-gray-600 tw-mr-1">/{{ dynamicPropertyData().length }}</span>
                <ui-button
                    (click)="next()"
                    ui-theme="minimal"
                    [svgIcon]="'arrow-right'"
                    class="tw-bg-gray-100"
                    [disabled]="currentPage() === dynamicPropertyData().length">
                </ui-button>
            </div>
        </div>
    }
</div>

<ui-dialog
    #previewDialog="ui-dialog"
    [config]="{ maxWidth: '90%', width: 'auto', height: '100%', padding: 0, headerText: 'Preview' }">
    <ng-template ui-dialog-template>
        <div class="tw-relative tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-8">
            <div class="tw-flex tw-items-center tw-justify-center">
                <creative-preview
                    [style.width.px]="dialogPreviewSize().width"
                    [style.height.px]="dialogPreviewSize().height"
                    [selectedCreative]="{ creativeId: creative().id, creativeSetId: creative().creativeSetId }"
                    [dynamicPropertyData]="currentPreviewData()" />
            </div>

            @if (dynamicPropertyData().length > 0) {
                <div class="tw-mt-4 tw-flex tw-items-center tw-justify-center tw-gap-2">
                    <ui-button
                        (click)="previous()"
                        ui-theme="minimal"
                        [svgIcon]="'arrow-left'"
                        class="tw-bg-gray-100"
                        [disabled]="currentPage() === 1">
                    </ui-button>
                    <span>{{ currentPage() }} / {{ dynamicPropertyData().length }}</span>
                    <ui-button
                        (click)="next()"
                        ui-theme="minimal"
                        [svgIcon]="'arrow-right'"
                        class="tw-bg-gray-100"
                        [disabled]="currentPage() === dynamicPropertyData().length">
                    </ui-button>
                </div>
            }
        </div>
    </ng-template>
</ui-dialog>
