import { FormArray } from '@angular/forms';
import { BaseFieldGroup, CreativeFieldMapping } from '../models/feed-form.model';
import { CreativeDynamicField, FeedField, FeedFieldType, FeedUpdateInterval } from '../models/feed.model';
import { Filter, FilterGroup, FilterGroupForm } from '../models/filters.model';

export function readableUpdateIntervals(): { value: FeedUpdateInterval; label: string }[] {
    return [
        { value: FeedUpdateInterval.None, label: 'Manual' },
        { value: FeedUpdateInterval.TwentyMinute, label: 'Every 20 minutes' },
        { value: FeedUpdateInterval.ThirtyMinutes, label: 'Every 30 minutes' },
        { value: FeedUpdateInterval.OneHour, label: 'Every hour' },
        { value: FeedUpdateInterval.ThreeHours, label: 'Every 3 hours' },
        { value: FeedUpdateInterval.SixHours, label: 'Every 6 hours' },
        { value: FeedUpdateInterval.TwelveHours, label: 'Every 12 hours' },
        { value: FeedUpdateInterval.EveryDay, label: 'Every day' },
        { value: FeedUpdateInterval.EveryWeek, label: 'Every week' }
    ];
}

export function transformFilterFormToFilterGroups(filterForm: FormArray<FilterGroupForm>): FilterGroup[] {
    return filterForm.controls.map(filterGroupControl => {
        const label = filterGroupControl.controls.label.value;
        const arrayGroup = filterGroupControl.controls.arrayGroup.value;
        const filterType = filterGroupControl.controls.filterType.value;
        const filtersFormArray = filterGroupControl.controls.filters;

        const filters: Filter[] = filtersFormArray.controls.map(filterControl => ({
            field: filterControl.controls.field.value,
            operator: filterControl.controls.operator.value,
            arguments: filterControl.controls.arguments.value
        }));

        const filterGroup: FilterGroup = {
            arrayGroup,
            filterType,
            filters
        };

        if (label) {
            filterGroup.label = label;
        }

        return filterGroup;
    });
}

export function convertFormCreativeFieldMappingsToCreativeMappings(
    creativeFieldMapping: FormArray<CreativeFieldMapping>
): CreativeDynamicField[] {
    return creativeFieldMapping.controls
        .filter(control => control.controls.data.value !== '')
        .map(control => {
            switch (control.controls.$type.value) {
                case FeedFieldType.Path:
                    return {
                        $type: FeedFieldType.Path,
                        dynamicPropertyId: control.controls.dynamicPropertyId.value,
                        dynamicPropertyLabel: control.controls.dynamicPropertyLabel.value,
                        dynamicPropertyType: control.controls.dynamicPropertyType.value,
                        path: control.controls.data.value
                    };
                case FeedFieldType.StaticText:
                    return {
                        $type: FeedFieldType.StaticText,
                        dynamicPropertyId: control.controls.dynamicPropertyId.value,
                        dynamicPropertyLabel: control.controls.dynamicPropertyLabel.value,
                        dynamicPropertyType: control.controls.dynamicPropertyType.value,
                        text: control.controls.data.value
                    };
                case FeedFieldType.Expression:
                    return {
                        $type: FeedFieldType.Expression,
                        dynamicPropertyId: control.controls.dynamicPropertyId.value,
                        dynamicPropertyLabel: control.controls.dynamicPropertyLabel.value,
                        dynamicPropertyType: control.controls.dynamicPropertyType.value,
                        expression: control.controls.data.value
                    };
                default:
                    throw new Error('Unknown field type');
            }
        });
}

export function convertBaseFieldGroupToFeedField(blueprintFields: FormArray<BaseFieldGroup>): FeedField[] {
    return blueprintFields.controls.map(field => {
        const id = field.controls.id.value;
        const name = field.controls.name.value;
        const $type = field.controls.$type.value;
        const required = field.controls.required.value;
        const metaData = field.controls.metaData;

        if (!metaData) {
            throw new Error('Meta data is required');
        }

        switch ($type) {
            case FeedFieldType.Creative:
                return {
                    $type,
                    id,
                    name,
                    required,
                    creativeId: metaData.controls[FeedFieldType.Creative].controls.creativeId.value,
                    creativeSetId: metaData.controls[FeedFieldType.Creative].controls.creativeSetId.value,
                    mappings: convertFormCreativeFieldMappingsToCreativeMappings(
                        metaData.controls[FeedFieldType.Creative].controls.mappings
                    )
                };
            case FeedFieldType.Path:
                return {
                    $type,
                    id,
                    name,
                    required,
                    path: metaData.controls[FeedFieldType.Path].controls.path.value
                };
            case FeedFieldType.StaticText:
                return {
                    $type,
                    id,
                    name,
                    required,
                    text: metaData.controls[FeedFieldType.StaticText].controls.text.value
                };
            case FeedFieldType.Expression:
                return {
                    $type,
                    id,
                    name,
                    required,
                    expression: metaData.controls[FeedFieldType.Expression].controls.expression.value
                };
            default:
                throw new Error('Unknown field type');
        }
    });
}

export function convertFilterGroupFormToFilterGroups(filterGroups: FormArray<FilterGroupForm>): FilterGroup[] {
    return filterGroups.getRawValue().map(({ filters, ...group }) => ({
        label: group.label,
        arrayGroup: group.arrayGroup,
        filterType: group.filterType,
        filters: filters.map(filter => ({
            operator: filter.operator,
            arguments: filter.arguments,
            field: filter.field
        }))
    }));
}
