import { Injectable, inject } from '@angular/core';
import { FormArray, NonNullableFormBuilder, Validators } from '@angular/forms';
import { FeedForm, BaseFieldGroup, CreativeFieldMapping } from 'src/app/models/feed-form.model';
import { CreativeDynamicField, DynamicFieldType, FeedField, FeedFieldType, MainFeed } from 'src/app/models/feed.model';
import { Filter, FilterForm, FilterGroup, FilterGroupForm } from 'src/app/models/filters.model';

@Injectable({
    providedIn: 'root'
})
export class TemplateBuilderService {
    fb = inject(NonNullableFormBuilder);

    buildForm(mainFeed: MainFeed): FeedForm {
        const group = this.fb.group({
            sourceUrl: this.fb.control(mainFeed.sourceUrl),
            template: this.fb.group({
                id: this.fb.control(mainFeed.templateId || ''),
                name: this.fb.control(mainFeed.name)
            }),
            blueprintFields: this.buildFieldsArray(mainFeed.blueprint.fields),
            filterGroups: this.buildFilterArrayGroups(mainFeed.filters?.filterGroups || []),
            updateInterval: this.fb.control(mainFeed.updateInterval),
            rootArrayPath: this.fb.control(mainFeed.rootArrayPath),
        });

        return group;
    }

    private buildFieldsArray(fields: FeedField[]): FormArray<BaseFieldGroup> {
        return this.fb.array(fields.map(field => this.buildFieldGroup(field, !field.required)));
    }

    buildFieldGroup(field: FeedField, removeable: boolean = false, editable = false): BaseFieldGroup {
        const baseGroup: BaseFieldGroup = this.fb.group({
            $type: this.fb.control(field.$type),
            id: this.fb.control(field.id),
            name: this.fb.control(field.name),
            required: this.fb.control(field.required),
            metaData: this.fb.group({
                [FeedFieldType.Creative]: this.fb.group({
                    creativeId: this.fb.control(field.$type === FeedFieldType.Creative ? field.creativeId : ''),
                    creativeSetId: this.fb.control(field.$type === FeedFieldType.Creative ? field.creativeSetId : ''),
                    mappings: this.createMappingsFromCreativeDynamicField(
                        field.$type === FeedFieldType.Creative ? field.mappings : []
                    )
                }),
                [FeedFieldType.Path]: this.fb.group({
                    path: this.fb.control(
                        field.$type === FeedFieldType.Path ? field.path : '',
                        field.$type === FeedFieldType.Path ? Validators.required : null
                    )
                }),
                [FeedFieldType.StaticText]: this.fb.group({
                    text: this.fb.control(
                        field.$type === FeedFieldType.StaticText ? field.text : '',
                        field.$type === FeedFieldType.StaticText ? Validators.required : null
                    )
                }),
                [FeedFieldType.Expression]: this.fb.group({
                    expression: this.fb.control(
                        field.$type === FeedFieldType.Expression ? field.expression : '',
                        field.$type === FeedFieldType.Expression ? Validators.required : null
                    )
                })
            }),
            removeable: this.fb.control(removeable),
            editable: this.fb.control(editable)
        });

        return baseGroup;
    }

    createMappingsFromCreativeDynamicField(
        creativeDynamicFields: CreativeDynamicField[]
    ): FormArray<CreativeFieldMapping> {
        return this.fb.array<CreativeFieldMapping>(
            creativeDynamicFields.map(creativeDynamicField =>
                this.createMappingFromCreativeDynamicField(creativeDynamicField)
            )
        );
    }

    createMappingFromCreativeDynamicField(creativeDynamicField: CreativeDynamicField): CreativeFieldMapping {
        if (!creativeDynamicField.dynamicPropertyLabel) {
            throw new Error('Dynamic property label is not set');
        }

        let dataValue: string;

        switch (creativeDynamicField.$type) {
            case FeedFieldType.Path:
                dataValue = creativeDynamicField.path;
                break;
            case FeedFieldType.StaticText:
                dataValue = creativeDynamicField.text;
                break;
            case FeedFieldType.Expression:
                dataValue = creativeDynamicField.expression;
                break;
            default:
                throw new Error('Unsupported FeedFieldType');
        }

        return this.fb.group({
            $type: this.fb.control<FeedFieldType>(creativeDynamicField.$type),
            dynamicPropertyId: this.fb.control<string>(creativeDynamicField.dynamicPropertyId),
            dynamicPropertyType: this.fb.control<DynamicFieldType>(creativeDynamicField.dynamicPropertyType),
            dynamicPropertyLabel: this.fb.control<string>(creativeDynamicField.dynamicPropertyLabel),
            data: this.fb.control<string>(dataValue)
        });
    }

    buildFilterArrayGroups(filterGroups: FilterGroup[]): FormArray<FilterGroupForm> {
        return this.fb.array(filterGroups.map(group => this.buildFilterGroup(group)));
    }

    buildFilterGroup(group: FilterGroup, isOpen: boolean = false): FilterGroupForm {
        const arrayGroupValue = '[]'; // set default value to root

        const formGroup: FilterGroupForm = this.fb.group({
            arrayGroup: this.fb.control(arrayGroupValue, {
                validators: [Validators.required]
            }),
            isFilterGroupOpen: this.fb.control(isOpen),
            label: this.fb.control(group.label || '', { validators: [Validators.required] }),
            filterType: this.fb.control(group.filterType, { validators: [Validators.required] }),
            filters: this.buildFilters(group.filters)
        });

        return formGroup;
    }

    private buildFilters(filters: Filter[]): FormArray {
        return this.fb.array(filters.map(filter => this.buildFilter(filter)));
    }

    buildFilter(filter: Filter): FilterForm {
        return this.fb.group({
            field: this.fb.control(filter.field !== '' ? filter.field : '', Validators.required),
            operator: this.fb.control(filter.operator, Validators.required),
            arguments: this.fb.array(filter.arguments.map(argument => this.fb.control(argument, Validators.required)))
        });
    }
}
