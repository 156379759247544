import { Pipe, PipeTransform } from '@angular/core';
import { Creative } from '../models/creative.model';

@Pipe({
    name: 'creativeDetails'
})
export class CreativeDetailsPipe implements PipeTransform {
    transform(selectedCreative: Creative): string {
        const { creativeSetName, version, size } = selectedCreative;

        return `${creativeSetName} [${version.name}, ${size.width} x ${size.height}]`;
    }
}
