@if (isSaved()) {
    <div
        [@fadeInOut]
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-20">
        <h2>Feed has been created</h2>

        <ui-button
            text="Go to list of feeds"
            (click)="goToBFCListUrl()"></ui-button>
    </div>
} @else {
    <div class="tw-flex tw-justify-center tw-items-center">
        <filters
            [filterForm]="filterGroups()"
            [rootArrayPath]="rootArrayPath()"
            class="tw-w-screen tw-px-12 tw-py-0" />
    </div>
}
