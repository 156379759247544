import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { FilterGroupForm } from 'src/app/models/filters.model';
import { OperatorsService } from 'src/app/services/api/operators.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { FilterSummaryComponent } from 'src/app/shared/filter-summary/filter-summary.component';
import { PathFieldComponent } from '../path-field/path-field.component';
@Component({
    selector: 'filters',
    imports: [
        CommonModule,
        UIModule,
        EditableFieldComponent,
        ReactiveFormsModule,
        MatSelectModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        FilterSummaryComponent,
        PathFieldComponent
    ],
    templateUrl: './filters.component.html',
    styleUrl: './filters.component.scss'
})
export class FiltersComponent {
    filterForm = input.required<FormArray<FilterGroupForm>>();
    rootArrayPath = input<string>('');
    private operatorsService = inject(OperatorsService);
    private templateBuilder = inject(TemplateBuilderService);
    operators$ = this.operatorsService.getAllOperators();
    actionOptions: { label: string; icon: Icon }[] = [{ label: 'Filter', icon: 'filter-2' }];

    addGroupFilter(): void {
        const newFilterGroup = this.templateBuilder.buildFilterGroup(
            {
                label: this.filterForm().controls.length + 1 + ' - ' + 'My new Filter',
                arrayGroup: '[]',
                filters: [{ field: '', operator: '', arguments: [''] }],
                filterType: 'Include'
            },
            true
        );

        this.filterForm().push(newFilterGroup);
    }

    removeFilterGroup(i: number) {
        this.filterForm().removeAt(i);
    }

    addFilter(filterGroup: FilterGroupForm, filterIndex: number) {
        const newFilter = this.templateBuilder.buildFilter({
            field: filterGroup.controls.filters.at(filterIndex).controls.field.value.toString(),
            operator: filterGroup.controls.filters.at(filterIndex).controls.operator.value,
            arguments: ['']
        });

        filterGroup.controls.filters.push(newFilter);
    }

    removefilter(filterGroup: FilterGroupForm, filterIndex: number) {
        filterGroup.controls.filters.removeAt(filterIndex);
    }

    toggleCard(isFilterGroupOpen: FormControl<boolean>) {
        isFilterGroupOpen.setValue(!isFilterGroupOpen.value);
    }
}
