import { ComponentPortal } from '@angular/cdk/portal';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal, viewChild } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { FFFeatureFlagsDirective } from '@bannerflow/feature-flags';
import { NSNotificationsComponent } from '@bannerflow/notification';
import { UIDialogDirective, UIModule, UINotificationService } from '@bannerflow/ui';
import { JsonSchema } from 'json-schema-library';
import { catchError, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { FeatureFlags } from 'src/app/models/feature-flags.model';
import { BaseFieldGroup, FeedForm } from 'src/app/models/feed-form.model';
import { FeedFieldType, FeedUpdateInterval } from 'src/app/models/feed.model';
import { FilterGroupForm } from 'src/app/models/filters.model';
import { Stepper } from 'src/app/models/stepper.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { NavigationService } from 'src/app/services/api/navigation.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { BottomSheetPreviewComponent } from 'src/app/shared/bottom-sheet-preview/bottom-sheet-preview.component';
import { HeaderNavigationComponent } from 'src/app/shared/header-navigation/header-navigation.component';
import { JsonSchemaTreeComponent } from 'src/app/shared/json-schema-tree/json-schema-tree.component';
import { convertBaseFieldGroupToFeedField, convertFilterGroupFormToFilterGroups } from 'src/app/utils/main-feed-helper';
import { environment } from 'src/environments/environment';
import { ConnectCreativeComponent } from './connect-creative/connect-creative.component';
import { ConnectFeedComponent } from './connect-feed/connect-feed.component';
import { CustomiseFeedComponent } from './customise-feed/customise-feed.component';
import { SelectFieldsComponent } from './select-fields/select-fields.component';
import { TemplatesComponent } from './templates/templates.component';

@Component({
    selector: 'stepper',
    imports: [
        CommonModule,
        UIModule,
        MatButtonModule,
        MatStepperModule,
        ConnectFeedComponent,
        CustomiseFeedComponent,
        SelectFieldsComponent,
        MatIconModule,
        TemplatesComponent,
        HeaderNavigationComponent,
        ConnectCreativeComponent,
        BottomNavigationComponent,
        NSNotificationsComponent,
        FFFeatureFlagsDirective,
        JsonSchemaTreeComponent
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ],
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
    stepper = viewChild.required<MatStepper>('stepper');
    schemaDialog = viewChild.required<UIDialogDirective>('schemaDialog');
    private route = inject(Router);
    private feedSourceService = inject(FeedSourceService);
    private navigationService = inject(NavigationService);
    private mainfeedService = inject(MainFeedService);
    private fb = inject(NonNullableFormBuilder);
    private uiNotificationService = inject(UINotificationService);
    private previewService = inject(PreviewService);
    private draggableBottomSheetService = inject(DraggableBottomSheetService);

    mainFeedId$ = this.mainfeedService.mainFeedId$;
    showSelectCreativeStep$: Observable<boolean>;
    Stepper = Stepper;
    formGroup: FeedForm;
    loading = signal(false);
    isFiltersSaved = signal(false);
    FeatureFlags = FeatureFlags;
    schema: JsonSchema;

    constructor() {
        effect(() => {
            const schema = this.feedSourceService.jsonSchema();
            if (schema) {
                this.schema = schema;
            }
        });
        this.formGroup = this.fb.group({
            sourceUrl: this.fb.control<string>('', Validators.required),
            template: this.fb.group({
                id: this.fb.control('', Validators.required),
                name: this.fb.control('', Validators.required)
            }),
            blueprintFields: this.fb.array<BaseFieldGroup>([]),
            filterGroups: this.fb.array<FilterGroupForm>([]),
            updateInterval: this.fb.control(FeedUpdateInterval.None),
            rootArrayPath: this.fb.control<string>('')
        });

        this.showSelectCreativeStep$ = this.formGroup.controls.blueprintFields.valueChanges.pipe(
            map(fields => fields.some(field => field.$type && field.$type === FeedFieldType.Creative))
        );

        this.formGroup.controls.sourceUrl.valueChanges.subscribe(value => {
            this.feedSourceService.setFeedSourceUrl(value);
        });

        this.formGroup.valueChanges
            .pipe(
                filter(_ => this.formGroup.valid),
                switchMap(_ => {
                    const fields = convertBaseFieldGroupToFeedField(this.formGroup.controls.blueprintFields);
                    const filters = convertFilterGroupFormToFilterGroups(this.formGroup.controls.filterGroups);

                    return this.previewService.getPreviewData({
                        source: this.formGroup.controls.sourceUrl.value,
                        fields: fields,
                        filters: filters,
                        rootArrayPath: this.formGroup.controls.rootArrayPath.value
                    });
                })
            )
            .subscribe();
    }

    navigateToBFCList(url: string): void {
        if ((environment.stage = 'proxy')) {
            this.route.navigate(['list']);
        }
        window.location.href = url;
    }

    connectFeed(): void {
        const openSchemaDialog = (schema: JsonSchema) =>
            schema['type'] === 'object' && this.formGroup.controls.rootArrayPath.value === '';

        const handleSchema = (schema: JsonSchema) => {
            if (openSchemaDialog(schema)) {
                this.schemaDialog().open();
            } else {
                this.stepper().next();
            }
        };

        const schema = this.feedSourceService.jsonSchema();
        if (schema) {
            handleSchema(schema);
            return;
        }

        this.loading.set(true);
        this.feedSourceService
            .getJsonSchema()
            .pipe(
                tap(handleSchema),
                catchError(error => {
                    console.error('Error fetching JSON schema:', error);
                    this.loading.set(false);
                    return of(null);
                })
            )
            .subscribe(() => {
                this.loading.set(false);
            });
    }

    stepChanged(event: StepperSelectionEvent): void {
        if (event.selectedStep.label === Stepper.CustomiseYourFeed) {
            const componentPortal = new ComponentPortal(BottomSheetPreviewComponent);
            this.draggableBottomSheetService.createBottomSheet(componentPortal, '40vh');
        } else {
            this.draggableBottomSheetService.removeBottomSheet();
        }
    }

    saveBlueprint(mainFeedId?: string): void {
        this.loading.set(true);
        const fields = convertBaseFieldGroupToFeedField(this.formGroup.controls.blueprintFields);
        const url = this.formGroup.controls.sourceUrl.value;
        const name = this.formGroup.controls.template.controls.name.value;
        const templateId = this.formGroup.controls.template.controls.id.value;
        const rootArrayPath = this.formGroup.controls.rootArrayPath.value;
        const filterGroups = this.formGroup.controls.filterGroups;

        let saveBlueprint: Observable<string>;

        if (mainFeedId) {
            saveBlueprint = this.mainfeedService.updateMainFeed({
                id: mainFeedId,
                name: name,
                templateId: templateId,
                sourceUrl: url,
                blueprint: { fields: fields },
                updateInterval: FeedUpdateInterval.None,
                filters: { filterGroups: convertFilterGroupFormToFilterGroups(filterGroups) },
                rootArrayPath: rootArrayPath
            });
        } else {
            saveBlueprint = this.mainfeedService.saveMainFeed(url, fields, name, templateId, rootArrayPath);
        }
        saveBlueprint.subscribe({
            next: () => {
                this.uiNotificationService.open(`Saved successfully`, {
                    type: 'success',
                    autoCloseDelay: 3000,
                    placement: 'top'
                });
                this.loading.set(false);
                if (this.stepper().selected?.label === Stepper.CustomiseYourFeed) {
                    this.isFiltersSaved.set(true);
                    this.draggableBottomSheetService.removeBottomSheet();
                } else {
                    this.stepper().next();
                }
            },
            error: error => {
                this.loading.set(false);
                this.uiNotificationService.open(error.message, {
                    type: 'error',
                    autoCloseDelay: 3000,
                    placement: 'top'
                });
            }
        });
    }

    goToBFCListUrl(): void {
        this.navigationService.navigateToBFCListUrl().pipe(take(1)).subscribe();
    }

    onPathSelected(path: string) {
        this.formGroup.controls.rootArrayPath.setValue(path);

        this.schemaDialog().close();
    }
}
