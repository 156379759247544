<cdk-tree
    [dataSource]="dataSource"
    [childrenAccessor]="childrenAccessor"
    #tree
    class="tw-bg-transparent tw-p-4">
    <!-- Tree node template -->
    <cdk-nested-tree-node
        #treeNode="cdkNestedTreeNode"
        *cdkTreeNodeDef="let node"
        class="tw-block tw-min-h-[50px] tw-truncate tw-outline-none">
        <div class="tw-flex tw-items-center tw-gap-2 tw-py-1">
            @if (!onlyArraySelection() || node.type === 'array') {
                <ui-checkbox
                    color="primary"
                    (selectedChange)="toggleNode(node)"
                    [selected]="selection.isSelected(node)" />
            }
            <span class="tw-flex tw-text-sm tw-font-semibold tw-items-center tw-gap-1 tw-text-ui-primary">
                {{ node.name }}
            </span>
        </div>
        <span
            [uiTooltip]="node.examples?.length ? getExampleTooltip(node.examples) : undefined"
            class="tw-text-sm tw-text-ui-grey-61 tw-italic tw-outline-none"
            [class.tw-pl-6]="!onlyArraySelection() || node.type === 'array'">
            {{ node.examples?.length ? node.examples : 'No data found' }}
        </span>
    </cdk-nested-tree-node>
    <!-- Expandable tree node template -->
    <cdk-nested-tree-node
        #treeNode="cdkNestedTreeNode"
        *cdkTreeNodeDef="let node; when: hasChild"
        class="tw-block tw-min-h-[50px] hover:tw-bg-ui-grey-98 tw-truncate tw-outline-none">
        <div class="tw-flex tw-items-center tw-gap-2 tw-py-1">
            <ui-svg-icon
                class="arrow"
                [icon]="tree.isExpanded(node) ? 'arrow-up' : 'arrow-down'"
                cdkTreeNodeToggle />
            @if (onlyArraySelection() && node.type === 'array') {
                <ui-checkbox
                    color="primary"
                    (selectedChange)="toggleNode(node)"
                    [selected]="selection.isSelected(node)" />
            }
            <span
                class="tw-flex tw-text-sm tw-items-center tw-gap-1 tw-font-semibold tw-text-ui-primary"
                [matTooltip]="getExampleTooltip(node.examples)"
                [matTooltipDisabled]="!node.examples?.length">
                {{ node.name }}
            </span>
            @if (node.type === 'array' && node.items.properties) {
                <span class="tw-flex tw-text-sm tw-items-center tw-gap-1">
                    {{
                        '[ ' +
                            Object.keys(node.items.properties).length +
                            ' sub field' +
                            (Object.keys(node.items.properties).length > 0 ? 's' : '') +
                            ' ]'
                    }}
                </span>
            }
            @if (node.type === 'object' && node.properties) {
                <span class="tw-flex tw-text-sm tw-items-center tw-gap-1">
                    {{
                        '[ ' +
                            Object.keys(node.properties).length +
                            ' propert' +
                            (Object.keys(node.properties).length > 0 ? 'ies' : 'y') +
                            ' ]'
                    }}
                </span>
            }
        </div>
        @if (node.examples?.length) {
            <span
                [uiTooltip]="getExampleTooltip(node.examples)"
                class="tw-text-sm tw-text-ui-grey-61 tw-italic tw-pl-6"
                >{{ node.examples }}</span
            >
        }
        <!-- Indented children -->
        @if (tree.isExpanded(node)) {
            <div class="tw-ml-12">
                <ng-container cdkTreeNodeOutlet></ng-container>
            </div>
        }
    </cdk-nested-tree-node>
</cdk-tree>
