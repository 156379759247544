import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { BaseFieldGroup, FeedForm } from 'src/app/models/feed-form.model';
import { FeedFieldType } from 'src/app/models/feed.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { SkeletonSelectFieldComponent } from './skeleton-select-field/skeleton-select-field.component';
import { TemplateFieldsComponent } from './template-fields/template-fields.component';
import { removeRootArrayPathFromPath } from 'src/app/utils/format-path';

@Component({
    selector: 'select-fields',
    imports: [CommonModule, UIModule, TemplateFieldsComponent, SkeletonSelectFieldComponent],
    templateUrl: './select-fields.component.html',
    styleUrls: ['./select-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldsComponent {
    feedForm = input.required<FeedForm>();

    private mainfeedService = inject(MainFeedService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);
    private feedSourceService = inject(FeedSourceService);
    private templateBuilderSerivce = inject(TemplateBuilderService);
    mainFeedId$ = this.mainfeedService.mainFeedId$;
    loading = signal(false);

    constructor() {
        effect(() => {
            this.getSuggestedFields();
        });
    }

    getSuggestedFields(): void {
        this.loading.set(true);
        this.mainFeedFieldsService
            .getSuggestedFields(this.feedForm().controls.template.controls.id.value)
            .subscribe(fields => {
                fields.forEach(field => {
                    const fieldGroup = this.templateBuilderSerivce.buildFieldGroup(field);
                    this.feedForm().controls.blueprintFields.push(fieldGroup);
                });
                this.loading.set(false);
            });
    }

    clickLoadDummyData(): void {
        const formArray = this.feedForm().controls.blueprintFields;
        const pathControllers = formArray.controls.filter(
            formGroup => formGroup.controls.$type.value === FeedFieldType.Path
        );

        // Get the rootArrayPath from the feedForm
        const rootArrayPath = this.feedForm().controls.rootArrayPath?.value;

        const handleSchema = (schema: any) => {
            if (!schema) {
                console.warn('Schema is missing.');
                return;
            }

            // Find all JMESPath expressions in the schema that are not arrays or objects
            const jmesPathExpressions: string[] = [];

            // Recursive function to extract JMESPath expressions from schema
            const extractJmesPathExpressions = (obj: any) => {
                if (!obj) return;

                // If the current object has properties, check each property
                if (obj.properties) {
                    Object.keys(obj.properties).forEach(key => {
                        const prop = obj.properties[key];
                        extractJmesPathExpressions(prop);
                    });
                }

                // If the current object has items (array), process those
                if (obj.items?.properties) {
                    Object.keys(obj.items.properties).forEach(key => {
                        const prop = obj.items.properties[key];

                        // Only include primitives (not arrays or objects)
                        if (prop.type && prop.type !== 'array' && prop.type !== 'object' && prop.jmesPath) {
                            jmesPathExpressions.push(prop.jmesPath);
                        }

                        extractJmesPathExpressions(prop);
                    });
                }
            };

            extractJmesPathExpressions(schema);

            if (jmesPathExpressions.length === 0) {
                console.warn('No valid JMESPath expressions found in schema.');
                return;
            }

            pathControllers.forEach(pathController => {
                const randomJmesPath = this.getRandomJmesPath(jmesPathExpressions);

                if (randomJmesPath) {
                    let processedPath = randomJmesPath;

                    // Remove rootArrayPath if it exists
                    if (rootArrayPath) {
                        processedPath = removeRootArrayPathFromPath(randomJmesPath, rootArrayPath);
                    }

                    this.setPathControl(pathController, processedPath);
                }
            });
        };

        handleSchema(this.feedSourceService.getFeedSchema());
    }

    private getRandomJmesPath(jmesPathExpressions: string[]): string {
        const randomIndex = Math.floor(Math.random() * jmesPathExpressions.length);
        return jmesPathExpressions[randomIndex];
    }

    private setPathControl(pathController: BaseFieldGroup<FeedFieldType>, path: string): void {
        const pathControl = pathController.controls.metaData?.controls?.[FeedFieldType.Path]?.controls?.path;

        if (pathControl) {
            pathControl.setValue(path);
        } else {
            console.warn('Path control is undefined for', pathController);
        }
    }
}
