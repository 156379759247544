import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { Draft2019, JsonSchema } from 'json-schema-library';
import { BehaviorSubject, Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FeedSourceService {
    private _feedSourceUrl$ = new BehaviorSubject<string>('');
    feedSourceUrl$ = this._feedSourceUrl$.asObservable();
    private http = inject(HttpClient);
    jsonSchema = signal<JsonSchema | null>(null);
    jsonSubSchema = signal<JsonSchema | null>(null);

    getJsonSchema(): Observable<JsonSchema> {
        return this._feedSourceUrl$.pipe(
            switchMap(url =>
                this.http.post<JsonSchema>(`${environment.origins.feedsFearsomeProcessor}/GenerateJSONSchema`, {
                    source: url,
                    exampleDataCount: 5
                })
            ),
            tap(schema => this.setJsonSchema(schema)),
            catchError(error => {
                console.error('Failed to fetch schema', error);
                return of([]);
            })
        );
    }

    getFeedSchema(): JsonSchema {
        const subJsonSchema = this.jsonSubSchema();
        const jsonSchema = this.jsonSchema();
        if (subJsonSchema) {
            return subJsonSchema;
        } else if (jsonSchema) {
            return jsonSchema;
        }
        throw new Error('Feed schema not found');
    }

    setSubFeedSchema(pointer: string) {
        const jsonSchema = this.jsonSchema();
        if (!jsonSchema) {
            return;
        }
        const draftSchema = new Draft2019(jsonSchema);
        const subSchema = draftSchema.getSchema({ pointer });

        if (subSchema) {
            this.jsonSubSchema.set(subSchema);
        } else {
            throw new Error('Sub schema not found');
        }
    }

    setJsonSchema(schema: JsonSchema) {
        this.jsonSchema.set(schema);
    }

    setFeedSourceUrl(url: string) {
        this._feedSourceUrl$.next(url);
    }
}
