import { ErrorHandler, inject, Injectable } from '@angular/core';
import { SentinelService } from '@bannerflow/sentinel';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    sentinelService = inject(SentinelService);

    handleError(error: Error | Event | string): void {
        environment.stage === 'production' || environment.stage === 'sandbox'
            ? this.handleProductionError(error)
            : this.handleDevelopmentError(error);
    }

    private handleProductionError(error: Error | Event | string): void {
        this.sentinelService.error(error);
    }

    private handleDevelopmentError(error: Error | Event | string): void {
        console.error('An error occurred:', error);
    }
}
