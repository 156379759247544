<div class="tw-flex tw-flex-col tw-justify-center tw-items-center">
    <h2>
        Now
        <div
            class="tw-inline"
            (dblclick)="clickLoadDummyData()">
            match
        </div>
        your fields with the template fields
    </h2>
    @if (loading()) {
        <skeleton-select-field class="tw-w-full" />
    } @else {
        <template-fields
            [feedForm]="feedForm()"
            class="tw-w-full" />
    }
</div>
