import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'card-summary',
    imports: [CommonModule, MatCardModule, UIModule],
    templateUrl: './card-summary.component.html',
    styleUrl: './card-summary.component.scss'
})
export class CardSummaryComponent {
}
