<div class="tw-px-13">
    <div class="tw-w-full tw-grid tw-grid-cols-3 tw-gap-y-0">
        <div>
            <h3 class="tw-text-ui-primary">Template fields</h3>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-end">
            <h3 class="tw-text-ui-primary">Imported fields from your source</h3>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-end tw-pl-2">
            <div class="tw-flex tw-justify-between">
                <h3 class="tw-text-ui-primary">Values from your source</h3>
            </div>
        </div>
    </div>
    <div class="tw-w-full tw-grid tw-grid-cols-3 tw-auto-rows tw-gap-x-3 tw-gap-y-3 tw-relative tw-py-3">
        @for (item of rows; let last = $last; track $index) {
            <div class="tw-grid tw-grid-cols-2 tw-gap-x-3 tw-gap-y-3 tw-col-span-2">
                <div class="tw-flex tw-items-center">
                    <ui-skeleton-cell class="skeleton-select-field tw-w-[100%]" />
                    <mat-icon class="tw-ml-3">link</mat-icon>
                </div>
                <div class="tw-flex tw-items-center tw-gap-2">
                    <ui-skeleton-cell class="skeleton-select-field tw-w-[30%]" />
                    <ui-skeleton-cell class="skeleton-select-field tw-w-[70%]" />
                </div>
            </div>
            @if (!last) {
                <div class="tw-border-b tw-border-gray-300 tw-col-span-2"></div>
            }
        }
        <div class="tw-col-start-3 tw-row-start-1 tw-row-end-[16] tw-flex tw-items-center tw-justify-center">
            <div class="tw-text-center tw-flex tw-items-center tw-justify-center">
                Data preview will become available as soon as you start mapping your fields.
            </div>
        </div>
    </div>
</div>
